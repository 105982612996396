import React from "react";
import classnames from "classnames";
import Link from "next/link";
import { useStoreState } from "pullstate";

import styles from "@/components/AppBar/components/TopBar/TopBar.module.scss";
import IconButton from "@/components/IconButton";
import CloseIcon from "@/icons/close-small.svg";
import { routePath } from "@/helpers/routePath";
import { logOut } from "@/helpers/logOut";
import { applicationRoutes } from "@/helpers/applicationRouting";
import { UIStore } from "@/store/UIStore";

interface BurgerMenuParams {
  menuOpen: boolean;
  closeBurger: () => void;
  openModal: (event: React.MouseEvent<HTMLElement>) => void;
}

export const BurgerMenu = ({
  menuOpen,
  closeBurger,
  openModal,
}: BurgerMenuParams) => {
  const isClientSide = typeof window !== "undefined";
  const isPWA =
    (isClientSide && window.navigator["standalone"]) ||
    process.env.NEXT_PUBLIC_MOBILE_APP === "true";
  const { userLoggedIn } = useStoreState(UIStore, (store) => store);

  return (
    <div
      className={classnames(
        styles.burgerMenu,
        menuOpen && styles.active,
        isPWA && styles.pwa,
      )}
      style={{ transformOrigin: "0 0" }}
    >
      <div className={styles.closeWrapper}>
        <IconButton
          size="small"
          onClick={closeBurger}
          className={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div className={styles.menuWrapper}>
        <ul className={styles.subMenu}>
          {!userLoggedIn ? (
            <li className={classnames(styles.subItem, styles.login)}>
              <Link href={routePath.LOGIN.href}>
                <a
                  className={classnames(
                    styles.subLink,
                    styles.special,
                    styles.login,
                  )}
                  onClick={openModal}
                >
                  Logowanie
                </a>
              </Link>
            </li>
          ) : (
            <li className={classnames(styles.subItem, styles.login)}>
              <a
                className={classnames(
                  styles.subLink,
                  styles.special,
                  styles.login,
                )}
                onClick={logOut}
              >
                Wyloguj się
              </a>
            </li>
          )}

          <li className={styles.subItem}>
            <Link href={routePath.AUTHORS.href}>
              <a className={classnames(styles.subLink, styles.special)}>
                {routePath.AUTHORS.label}
              </a>
            </Link>
          </li>

          <li className={styles.subItem}>
            <a
              href="https://praca.newonce.net/"
              className={classnames(styles.subLink, styles.special)}
              target="_blank"
              rel="noreferrer"
            >
              Praca
            </a>
          </li>

          <li className={styles.subItem}>
            <Link href={routePath.HELP.href}>
              <a className={classnames(styles.subLink, styles.special)}>
                {routePath.HELP.label}
              </a>
            </Link>
          </li>

          <li className={styles.subItem}>
            <Link href={routePath.CONTACT.href}>
              <a className={classnames(styles.subLink, styles.special)}>
                {routePath.CONTACT.label}
              </a>
            </Link>
          </li>

          {/*<li className={styles.subItem}>*/}
          {/*  <a*/}
          {/*    href={routePath.DISCORD.href}*/}
          {/*    className={classnames(styles.subLink, styles.special)}*/}
          {/*    target="_blank"*/}
          {/*    rel="noreferrer"*/}
          {/*  >*/}
          {/*    {routePath.DISCORD.label}*/}
          {/*  </a>*/}
          {/*</li>*/}

          {/*<li className={styles.subItem}>*/}
          {/*  <a*/}
          {/*    href="https://newonce.store/"*/}
          {/*    className={classnames(styles.subLink, styles.special)}*/}
          {/*    target="_blank"*/}
          {/*    rel="noreferrer"*/}
          {/*  >*/}
          {/*    newonce.store*/}
          {/*  </a>*/}
          {/*</li>*/}

          <li className={styles.subItem}>
            <Link
              href={routePath.PAGE.href}
              as={applicationRoutes.FEED + "feedback-i-pomysly"}
            >
              <a className={classnames(styles.subLink, styles.special)}>
                Feedback i pomysły
              </a>
            </Link>
          </li>

          <li className={styles.subItem}>
            <a
              href="https://www.youtube.com/@newonce"
              className={classnames(styles.subLink, styles.special)}
              target="_blank"
              rel="noreferrer"
            >
              YouTube @newonce
            </a>
          </li>

          <li className={styles.subItem}>
            <a
              href="https://www.youtube.com/@newonce_radio"
              className={classnames(styles.subLink, styles.special)}
              target="_blank"
              rel="noreferrer"
            >
              YouTube @newonce.radio
            </a>
          </li>
        </ul>

        <ul className={styles.subMenu}>
          <li className={styles.subItem}>
            <Link
              href={routePath.PAGE.href}
              as={
                applicationRoutes.FEED +
                "polityka-prywatnosci-platformy-newonce"
              }
            >
              <a className={classnames(styles.subLink, styles.special)}>
                Polityka Prywatności
              </a>
            </Link>
          </li>

          <li className={styles.subItem}>
            <Link
              href={routePath.PAGE.href}
              as={
                applicationRoutes.FEED +
                "regulamin-ogolny-korzystania-z-serwisu"
              }
            >
              <a className={classnames(styles.subLink, styles.special)}>
                Regulamin ogólny korzystania z serwisu
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
