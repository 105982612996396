import React, { MouseEvent, useEffect, useState } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import localforage from "localforage";
import classnames from "classnames";
import { useRouter } from "next/router";
import { useStoreState } from "pullstate";

import {
  applicationRouteNamespace,
  applicationRoutes,
} from "shared/helpers/applicationRouting";
import {
  authPaths,
  feedPaths,
  podcastPaths,
  routePath,
} from "@/helpers/routePath";
import { UIStore } from "@/store/UIStore";
import ArrowIcon from "@/icons/arrow-down-small.svg";
import MenuIcon from "@/icons/menu.svg";
import IconButton from "@/components/IconButton";
import Logotype from "@/components/Logotype";
import { AppBarInterface } from "@/components/AppBar/AppBar.interface";
import { FeedType } from "@/helpers/pageHelpers/Store/interface";
import { pageview } from "@/helpers/gtag";
import { BurgerMenu } from "@/components/AppBar/components/TopBar/BurgerMenu";
import UserButton from "@/components/AppBar/components/TopBar/UserButton";

import styles from "./TopBar.module.scss";
import Header from "./Header";

const AuthModal = dynamic(import("@/components/Modals/AuthModal"), {
  ssr: false,
});
const ArticleReadIndicator = dynamic(
  import("@/components/ArticleReadIndicator"),
  { ssr: false },
);

const bodyId = "body";
const bodyClass = "isMenuOpen";

export const TopBar: React.FC<AppBarInterface> = ({ navigationData }) => {
  const router = useRouter();
  const portal = process.env.NEXT_PUBLIC_PORTAL;
  const [savedFeed, setSavedFeed] = useState<FeedType>();
  const [isMenuOpen, openMenu] = useState<boolean>(false);
  const { userLoggedIn, feedType, authModalOpen } = useStoreState(
    UIStore,
    (store) => store,
  );
  const [isTogglerOpen, setTogglerOpen] = useState<boolean>(false);
  const isArticlePage = router.pathname.includes(
    applicationRouteNamespace.ARTICLE,
  );
  const isHomepage = feedPaths.includes(router.pathname);
  // const isRadio = radioPaths.includes(router.asPath);
  const isPodcasts = podcastPaths.includes(router.pathname);
  const showPersonalFeed =
    userLoggedIn &&
    feedType === FeedType.Personal &&
    savedFeed !== FeedType.Main;

  useEffect(() => {
    const resetModalOpen = !!router.query?.reset;

    UIStore.update((store) => {
      store.authModalOpen = resetModalOpen;
    });
  }, [router]);

  useEffect(() => {
    getSavedFeedType().then((val: FeedType) => {
      setSavedFeed(val);
    });
  }, [savedFeed, feedType]);

  useEffect(() => {
    const handleRouteChange = () => {
      closeBurgerMenu();
      pageview(window.location.pathname + window.location.search);
      if (router.pathname === "/") {
        window?.gemius_hit &&
          window.gemius_hit(process.env.NEXT_PUBLIC_GEMIUS_ID_HOMEPAGE);
      } else {
        window?.gemius_hit &&
          window.gemius_hit(process.env.NEXT_PUBLIC_GEMIUS_ID);
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  const getSavedFeedType = async () => {
    try {
      return await localforage.getItem("feedType");
    } catch (err) {}
  };

  const openModal = (event: MouseEvent<HTMLElement>) => {
    if (!authPaths.includes(router.pathname)) {
      event.preventDefault();
      closeBurgerMenu();
      UIStore.update((store) => {
        store.authModalOpen = true;
      });
    }
  };

  const closeModal = () => {
    UIStore.update((store) => {
      store.authModalOpen = false;
    });
  };

  const setFeed = (feed: FeedType) => {
    localforage.setItem("feedType", feed).then(() => {
      UIStore.update((store) => {
        store.feedType = feed;
      });

      setSavedFeed(feed);
      setTogglerOpen(false);
      router.push(applicationRoutes.FEED);
    });
  };

  const toggleFeedsMenu = () => {
    setTogglerOpen(!isTogglerOpen);
  };

  const renderFeedLabels = () => {
    if (userLoggedIn) {
      return (
        <div
          className={classnames(
            styles.largeFeedToggler,
            isHomepage && styles.active,
            isTogglerOpen && styles.open,
          )}
        >
          <Link href={routePath.FEED.href}>
            <a
              className={classnames(
                styles.navLink,
                styles.switch,
                isHomepage && styles.active,
                showPersonalFeed ? "personalFeed" : "feed",
              )}
            >
              {<routePath.FEED.iconSmall />}
              <span className={classnames(styles.nameLabel, styles[portal])}>
                {showPersonalFeed
                  ? routePath.PERSONAL_FEED.label
                  : routePath.FEED.label}
              </span>
            </a>
          </Link>

          <div className={styles.toggleArrow} onClick={toggleFeedsMenu}>
            <ArrowIcon />
          </div>

          <div
            className={classnames(
              styles.feedToggleMenu,
              styles[portal],
              isTogglerOpen && styles.active,
            )}
          >
            <ul className={styles.feedMenu}>
              <li
                className={styles.feedMenuItem}
                onClick={() => setFeed(FeedType.Main)}
              >
                {<routePath.FEED.iconSmall />}
                <span className={styles.feedMenuLabel}>
                  {routePath.FEED.label}
                </span>
              </li>
              <li
                className={styles.feedMenuItem}
                onClick={() => setFeed(FeedType.Personal)}
              >
                {<routePath.FEED.iconSmall />}
                <span className={styles.feedMenuLabel}>
                  {routePath.PERSONAL_FEED.label}
                </span>
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return (
      <Link href={routePath.FEED.href}>
        <a className={classnames(styles.navLink, isHomepage && styles.active)}>
          {<routePath.FEED.iconSmall />}
          <span className={classnames(styles.nameLabel, styles[portal])}>
            {routePath.FEED.label}
          </span>
        </a>
      </Link>
    );
  };

  const openBurgerMenu = () => {
    openMenu(true);

    window.scrollTo(0, 0);
    document.getElementById(bodyId).className = bodyClass;
  };

  const closeBurgerMenu = () => {
    openMenu(false);

    document.getElementById(bodyId).className = null;
  };

  return (
    <div className={styles.topBar}>
      <Header
        headerLabel={navigationData.headerLabel}
        backFallback={navigationData.fallbackHistoryBack}
      />

      <nav className={classnames(styles.navDesktop)}>
        <IconButton
          variant="round"
          onClick={openBurgerMenu}
          className={styles.burgerIcon}
        >
          <MenuIcon />
        </IconButton>

        <div className={styles.navWrapper}>
          {renderFeedLabels()}

          {/* Remove radio 29.11.2024 */}
          {/*<Link href={routePath.RADIO.href}>*/}
          {/*  <a className={classnames(styles.navLink, isRadio && styles.active)}>*/}
          {/*    {<routePath.RADIO.iconSmall />}*/}
          {/*    <span className={classnames(styles.nameLabel, styles[portal])}>*/}
          {/*      {routePath.RADIO.label}*/}
          {/*    </span>*/}
          {/*  </a>*/}
          {/*</Link>*/}

          <Link href={routePath.PODCASTS.href}>
            <a
              className={classnames(
                styles.navLink,
                isPodcasts && styles.active,
              )}
            >
              {<routePath.PODCASTS.iconSmall />}
              <span className={classnames(styles.nameLabel, styles[portal])}>
                {routePath.PODCASTS.label}
              </span>
            </a>
          </Link>

          <Link href={routePath.YOUTUBE.href}>
            <a className={styles.navLink} target="_blank" rel="noreferrer">
              {<routePath.YOUTUBE.icon />}
              <span
                className={classnames(
                  styles.music,
                  styles.nameLabel,
                  styles[portal],
                )}
              >
                {routePath.YOUTUBE.label}
              </span>
            </a>
          </Link>
        </div>
      </nav>

      <div className={classnames(styles.logo, "hide-on-mobile")}>
        <Link href={applicationRoutes.FEED} as={applicationRoutes.FEED}>
          <a className={styles.link}>
            <Logotype />
          </a>
        </Link>
      </div>
      <div className={styles.action}>
        <Link href={routePath.SEARCH.href}>
          <a className={classnames(styles.search, "hide-on-mobile")}>
            {<routePath.SEARCH.icon />}
          </a>
        </Link>

        <div className={styles.menuMobileWrapper}>
          <IconButton
            size="small"
            className={classnames(styles.iconColor, styles.menuMobile)}
            onClick={openBurgerMenu}
          >
            <MenuIcon />
          </IconButton>
        </div>

        <UserButton className={styles.userButton} />

        <AuthModal isOpen={authModalOpen} onClose={closeModal} />
      </div>

      {isArticlePage && <ArticleReadIndicator />}

      <BurgerMenu
        menuOpen={isMenuOpen}
        closeBurger={closeBurgerMenu}
        openModal={openModal}
      />
    </div>
  );
};

export default TopBar;
